import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loadable, { MyLoadable } from "../components/layout/Loadable";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AdminRouter from "./admin/AdminRouter";
import PublicRouter from "./PublicRouter";

const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const LoginFailed = Loadable(lazy(() => import("../pages/auth/LoginFailed")));
const NotFound = Loadable(lazy(() => import("../pages/errors/404")));

const DashboardLayout = Loadable(lazy(() => import("../components/layout/DashboardLayout")));
const Dashboard = Loadable(lazy(() => import("../pages/admin/Dashboard")));
const AdminProtectedRoute = MyLoadable(
  lazy(() => import("./AdminProtectedRoute"))
);

const queryClient = new QueryClient();

export default function AppRouter() {
  const RouteElement = useRoutes([
    {
      path: "/",
      element: (
        <AdminProtectedRoute>
          <DashboardLayout />
        </AdminProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={"/dashboard"} replace />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        ...AdminRouter,
      ],
    },
    {
      path: "/auth/login",
      element: (
        <PublicRouter>
          <Login />
        </PublicRouter>
      ),
    },
    {
      path: "/auth/error",
      element: (
        <PublicRouter>
          <LoginFailed />
        </PublicRouter>
      ),
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      {RouteElement}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
