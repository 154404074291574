import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  styled,
} from "@mui/material";
import Logo from "../../layout/Logo";

const CustomDialogBox = styled(Dialog)(({ theme }) => ({
  "& .MuiTypography-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function LoadingDialogBox(props) {
  const { open } = props;

  return (
    <CustomDialogBox open={open} className="backdrop-blur-md">
      <Box
        
        className="bg-secondary-main"

      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            width:"300px"
          }}
        >
          <Logo />
          <CircularProgress color="secondary" />
        </DialogContent>
      </Box>
    </CustomDialogBox>
  );
}
