/* eslint-disable import/no-anonymous-default-export */
import { lazy } from "react";
import Loadable from "../../components/layout/Loadable";

const Courses = Loadable(lazy(() => import("../../pages/admin/master/course")));
const AddCourse = Loadable(
  lazy(() => import("../../pages/admin/master/course/AddCourse"))
);
const EditCourse = Loadable(
  lazy(() => import("../../pages/admin/master/course/EditCourse"))
);
const ReorderCourse = Loadable(
  lazy(() => import("../../pages/admin/master/course/ReorderCourse"))
);

const Services = Loadable(
  lazy(() => import("../../pages/admin/master/services"))
);
const AddService = Loadable(
  lazy(() => import("../../pages/admin/master/services/AddService"))
);
const EditService = Loadable(
  lazy(() => import("../../pages/admin/master/services/EditService"))
);
const ReorderServices = Loadable(
  lazy(() => import("../../pages/admin/master/services/ReorderService"))
);

const Category = Loadable(
  lazy(() => import("../../pages/admin/master/category"))
);
const Enquiries = Loadable(
  lazy(() => import("../../pages/admin/enquiry/enquiries"))
);
const MentorEnquiries = Loadable(
  lazy(() => import("../../pages/admin/enquiry/mentorEnquiry"))
);
const Quotation = Loadable(
  lazy(() => import("../../pages/admin/enquiry/quotation"))
);
const Faqs = Loadable(lazy(() => import("../../pages/admin/faqs")));
const FeeManagement = Loadable(
  lazy(() => import("../../pages/admin/master/fee"))
);
const Testimonial = Loadable(
  lazy(() => import("../../pages/admin/testimonial"))
);
const Roles = Loadable(lazy(() => import("../../pages/admin/users/roles")));
const User = Loadable(lazy(() => import("../../pages/admin/users/user")));

const EditUser = Loadable(
  lazy(() => import("../../pages/admin/users/user/EditUser"))
);
const AddUser = Loadable(
  lazy(() => import("../../pages/admin/users/user/AddUser"))
);

const Plan = Loadable(
  lazy(() => import("../../pages/admin/master/plan"))
);

const UserCourse = Loadable(
  lazy(() => import("../../pages/admin/master/plan/UserCourse"))
)



export default [
  {
    path: "categories",
    children: [
      {
        path: "",
        element: <Category />,
      },
      {
        path: ":ID",
        element: <Category />,
      },
    ],
  },
  {
    path: "fee",
    element: <FeeManagement />,
  },
  {
    path: "plan",
    children: [
      {
        path: "",
        element: <Plan/>,
      },
      {
        path: "course/:ID",
        element: <UserCourse  />,
      },
    ],
  },
  {
    path: "course",
    children: [
      {
        path: "",
        element: <Courses />,
      },
      {
        path: "add",
        element: <AddCourse />,
      },
      {
        path: "edit/:ID",
        element: <EditCourse />,
      },
      {
        path: "reorder",
        element: <ReorderCourse />,
      },
    ],
  },
  {
    path: "services",
    children: [
      {
        path: "",
        element: <Services />,
      },
      {
        path: "add",
        element: <AddService />,
      },
      {
        path: "edit/:ID",
        element: <EditService />,
      },
      {
        path: "reorder",
        element: <ReorderServices />,
      },
    ],
  },
  {
    path: "enquiries",
    element: <Enquiries />,
  },
  {
    path: "mentor-enquiries",
    element: <MentorEnquiries />,
  },
  {
    path: "quoatation",
    element: <Quotation />,
  },
  {
    path: "faq",
    element: <Faqs />,
  },
  {
    path: "testimonial",
    element: <Testimonial />,
  },
  {
    path: "users",
    children: [
      {
        path: "",
        element: <User />,
      },
      {
        path: "add",
        element: <AddUser />,
      },
      {
        path: "edit/:ID",
        element: <EditUser />,
      },
      {
        path: "roles",
        element: <Roles />,
      },
      {
        path: "course/:ID",
        element: <UserCourse user={true}  />,
      },
    ],
  },
];
