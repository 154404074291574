import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  isAuthenticated: false,
  authInfo: {},
  timestamp: null,
  accessToken:""
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuthenticated=true;
      state.authInfo = action.payload.authInfo;
      state.accessToken= action.payload.accessToken
      state.timestamp= dayjs()

    },
    setLogout: (state) => {
      state.isAuthenticated = false;
      state.user = {};
    },
  },
});

export const { setAuth, setLogout } = authSlice.actions;

export default authSlice.reducer;
